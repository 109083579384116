import React from 'react';
import SlotMachine from '../components/SlotMachine';

const Penalty = () => {
  return (
    <div>
      <SlotMachine></SlotMachine>
    </div>
  );
};

export default Penalty;